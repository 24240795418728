* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
