.header {
  background-color: lightgreen;
  border-top: 5px solid orange;
  border-bottom: 5px solid orange;
  padding: 20px;
  text-align: center;
}

.header h1 {
  color: blue;
}
