.main {
  background-color: lightgreen;
  border: 2px solid orange;
  padding: 20px;
  text-align: center;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid orange;
  padding: 10px;
  text-align: center;
}

.schedule-table th {
  background-color: orange;
  color: white;
}

.schedule-table td {
  background-color: #fffacd;
}
